<template>
  <div>
    <Card>
        <Row>
          <Col span="8" offset="8">
            <Form ref="form" :model="form" :label-width="80" :rules="formValidate" label-position="left">
              <FormItem label="当前用户" prop="telno">
                <Input v-model="form.telno" :maxlength=11 readonly />
              </FormItem>
              <FormItem label="有效期限" prop="buyDue">
                <Input v-model="form.buyDue" :maxlength=11 readonly />
              </FormItem>
              <Form-item class="br">
                <Button @click="handleSubmit" type="primary">服务购买</Button>
                <Button @click="handleRefresh" type="warning">刷新，已完成支付</Button>
              </Form-item>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col span="8" offset="8">
            <span style="">1、在电脑上购买和App上购买的效果是一样的。</span>
          </Col>
          <Col span="8" offset="8">
            <span>2、在电脑上购买支持支付宝、微信两种支付方式。</span>
          </Col>
        </Row>
    </Card>
  </div>
</template>
<script>
import { appUserInfo } from "@/api/index";
export default {
    name: "buy",
    components: {},
    data() {
      return {
        loading: true, // 表单加载状态
        form: {
            telno: "",
            buyDue: "",
        },
      };
    },
    methods: {
      init() {
        this.getAppUserInfo();
      },
      handleSubmit() {
        // window.open("http://test.easyls.cn/xseed/pay/pay-index?platform=3&telno="+this.form.telno);
        window.open("http://my.easyls.cn/xseed/pay/pay-index?platform=3&telno="+this.form.telno);
      },
      handleRefresh() {
        this.getAppUserInfo();
      },
      getAppUserInfo() {
        this.loading = true;
        appUserInfo(this.form).then(res => {
          this.loading = false;
          if (res.success) {
            this.form.telno=res.result.telno;
            this.form.buyDue=res.result.buyDue;
            this.$Message.success("已刷新");
          }
        });
      },
    },
    mounted(){
      this.init();
    },
};
</script>
